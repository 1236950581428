import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import WhatsAppChat from "../../components/WhatsAppChat";
import Bulletin from "../Bulletin";
import Ebook from "../Ebook";
import Hero from "../Hero";
import Profile from "../Profile";
import Registration from "../Registration";
import Testimonials from "../Testimonials";

const Main = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Registration />
      <Bulletin />
      <Ebook />
      <Testimonials />
      <Profile />
      <Footer />
      <WhatsAppChat />
    </>
  );
};

export default Main;
