import { CopyrightContainer } from "./style";

const Copyright = () => {
  return (
    <CopyrightContainer>
      © 2021 by Alien Brains Educations. All rights reserved.
    </CopyrightContainer>
  );
};

export default Copyright;
