import styled from "styled-components";

export const StyledIcon = styled.img`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 70px;
  height: 70px;
  object-fit: contain;
  z-index: 1000000;

  @media (min-width: 768px) {
    width: 90px;
    height: 90px;
  }
`;
