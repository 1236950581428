import WhatsAppIcon from "../../assets/WhatsApp-icon.png";
import { StyledIcon } from "./style";

const WhatsAppChat = () => {
  return (
    <a href="https://api.whatsapp.com/send?phone=918420804831" target="_blank">
      <StyledIcon src={WhatsAppIcon} />
    </a>
  );
};

export default WhatsAppChat;
