import React, { useEffect, useState } from "react";
import { Cursor } from "./style";

export const LoopText = ({
  textData = [],
  typingSpeed = 150,
  deletingSpeed = 30,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState(" ");
  const [loopNum, setLoopNum] = useState(0);
  const [currentSpeed, setCurrentSpeed] = useState(typingSpeed);

  useEffect(() => {
    handleType();
  }, []);

  useEffect(() => {
    setTimeout(handleType, currentSpeed);
  }, [text, isDeleting]);

  const handleType = () => {
    const i = loopNum % textData.length;
    const fullText = textData[i];

    setText(() => {
      return isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1);
    });

    setCurrentSpeed(() => {
      return isDeleting ? deletingSpeed : typingSpeed;
    });

    if (!isDeleting && text === fullText) {
      setTimeout(() => {
        setIsDeleting(true);
      }, 500);
    } else if (isDeleting && text === "") {
      setIsDeleting(false);
      setLoopNum((prev) => prev + 1);
    }
  };
  return (
    <>
      {text} <Cursor />
    </>
  );
};
