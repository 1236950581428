import styled from "styled-components";

export const RibbonContainer = styled.div`
  position: absolute;
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  left: 0px;
  background-size: cover;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
`;

export const RibbonChild = styled.span`
  width: 260px;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -8px;
  background: ${(props) => props.$color};

  &::after,
  &::before {
    content: "";
    position: absolute;
  }

  &::before {
    height: 0;
    width: 0;
    top: -8.5px;
    left: 0.1px;
    border-bottom: 9px solid black;
    border-left: 9px solid transparent;
  }

  &::after {
    height: 0;
    width: 0;
    right: -14.5px;
    top: 0px;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 15px solid ${(props) => props.$color};
  }
`;
