import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 0 80px;
  }
`;

export const Card = styled.div`
  max-width: 500px;
  max-height: 250px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-radius: 25%/50%;
  background: #fff6dc;
  margin: 50px 0;
  text-align: left;

  & img {
    object-fit: contain;
    max-height: 210px;
    max-width: 40%;
  }

  & h3 {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 10px;
  }

  & p {
    font-size: 0.95rem;
    color: #5c5c5c;
  }

  @media (min-width: 768px) {
    gap: 30px;
    padding: 30px;

    & img {
      max-height: 190px;
      max-width: 50%;
    }
  }
`;
