import bgWeb from "../../assets/Registration-bg-web.png";
import bgMobile from "../../assets/Registration-bg-mobile.png";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff6dc url(${bgMobile}) no-repeat right top;
  padding: 20px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    padding: 50px 80px 20px;
    background: #fff6dc url(${bgWeb}) no-repeat right top;
  }
`;

export const StyledForm = styled.form`
  width: min(100%, 950px);
  background: #fff;
  margin: 50px 0;
  padding: 20px;
  border-radius: 10px;

  @media (min-width: 768px) {
    padding: 40px 80px;
  }
`;

export const StyledLabel = styled.label`
  color: #707070;
  display: block;
  margin-bottom: 5px;
  font-size: 0.9rem;

  & a {
    color: #222;
    text-decoration: underline;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  border: none;
  background: #f5f5f5;
  margin-bottom: 20px;

  &:focus {
    border: 1px solid #ccc;
    outline: none;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  alignn-items: center;
  justify-content: center;
  gap: 0;

  & div {
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  alignn-items: center;
  justify-content: center;
  gap: 10px;

  & div {
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  width: 100%;
  height: 46px;
  background: ${(props) => (props.$primary ? "#FFDD00" : "#000000")};
  color: ${(props) => (props.$primary ? "#000000" : "#FFDD00")};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: 5px 0 20px;

  &:hover {
    box-shadow: 0 15px 23px 0
      ${(props) => (props.$primary ? "#fff6dc" : "#00000030")};
  }
  &:active {
    ${(props) => !props.disabled && "transform: scale(0.9);"}
  }
`;

export const CustomCheckbox = styled.div`
  max-width: 20px;
  height: 20px;
  border: 3px solid #fece2f;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.$active && "background: #fece2f"};

  &:hover {
    box-shadow: 0 15px 23px 0 #fff6dc;
  }

  & img {
    display: ${(props) => (props.$active ? "" : "none")};
  }
`;

export const StyledError = styled.div`
  font-size: 0.95rem;
  color: #f93e3e;
  text-align: center;
  margin: -10px 0 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

  & img {
    width: 15px;
    margin-top: 4px;
  }
`;

export const StyledSuccess = styled.div`
  font-size: 0.95rem;
  color: #0da018;
  text-align: center;
  margin: -10px 0 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

  & img {
    width: 15px;
    margin-top: 4px;
  }
`;
