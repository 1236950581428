import { useState } from "react";
import { convertObjectToFormData } from "./utils";

export const useFetchOtp = () => {
  const [isLoading, setLoading] = useState(false);

  const fetchOtp = async (phone, email) => {
    const data = {
      verification: {
        email: email,
        phone: phone,
        modelable_type: "Webinar",
        modelable_id: 3,
      },
    };

    const formData = new FormData();
    convertObjectToFormData(data, formData, "");

    setLoading(true);

    const response = await fetch(
      "https://alientraining.herokuapp.com/api/v3/leads/generate_code",
      {
        method: "POST",
        body: formData,
      },
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return { message: error };
      });

    setLoading(false);
    return response;
  };

  return {
    isLoading,
    fetchOtp,
  };
};
