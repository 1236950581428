import styled from "styled-components";

export const Container = styled.div`
  padding: 51px 20px 0px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "content content"
    "company programs"
    "specialization brands";
  grid-row-gap: 40px;
  background-color: #fff6dc;
  margin-top: 30px;

  @media (min-width: 1000px) {
    padding: 80px 40px;
  }

  @media (min-width: 1024px) {
    padding: 80px 100px;
    grid-template-rows: none;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-template-areas: "content company programs specialization brands";
    justify-items: center;
  }
`;

export const ContentContainer = styled.div`
  grid-area: content;
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  & > .desc {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    color: #4b4b4b;
    line-height: 164%;
    @media (min-width: 1024px) {
      max-width: 285px;
    }
  }
`;
export const ContentLogo = styled.div`
  width: 227px;
  height: 68.55px;
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SocialIconBox = styled.a`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
  &:hover {
    background: #ffd24e;
    box-shadow: 0px 4px 10px rgba(255, 210, 78, 0.33);
    margin: 0 5px 0 -5px;
  }
  & > img {
    height: 15px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  & > .title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.37rem;
    color: black;
    margin-bottom: 23px;
  }

  & > .link_area {
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;
  }

  & a {
    color: #4b4b4b;
    text-decoration: none;

    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
`;

export const CompanyLinksContainer = styled(LinksContainer)`
  grid-area: company;
`;
export const ProgramsLinksContainer = styled(LinksContainer)`
  grid-area: programs;
`;
export const SpecializationsLinksContainer = styled(LinksContainer)`
  grid-area: specialization;
`;
export const BrandLinksContainer = styled(LinksContainer)`
  grid-area: brands;
`;

export const CopyrightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.9rem;
  color: black;
  background-color: #ffeaab;
  padding: 20px 10px;
  text-align: center;
`;
