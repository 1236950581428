export const convertObjectToFormData = (object, formData, prevKey) => {
  Object.keys(object).forEach((key) => {
    const item = object[key];
    const isFile = Object.prototype.toString.call(item) === "[object File]";
    const newKey = prevKey ? `${prevKey}[${key}]` : `${key}`;
    if (Array.isArray(item)) {
      appendArrayToFormData(formData, item, newKey);
    } else if (!isFile && typeof item === "object") {
      convertObjectToFormData(item, formData, newKey);
    } else {
      formData.append(newKey, item);
    }
  });
};

export const appendArrayToFormData = (formData, array = [], key) => {
  const newKey = `${key}[]`;

  if (array.length === 0) {
    formData.append(`${key}`, "");
  } else {
    array.forEach((item) => {
      const isFile = Object.prototype.toString.call(item) === "[object File]";
      if (Array.isArray(item)) {
        appendArrayToFormData(formData, item, newKey);
      } else if (!isFile && typeof item === "object") {
        convertObjectToFormData(item, formData, newKey);
      } else {
        formData.append(newKey, item);
      }
    });
  }
};

export const createErrorMessage = (errors) => {
  if (Object.keys(errors).length > 0) {
    return Object.keys(errors)
      .map((key) => errors[key].join(", "))
      .join(", ");
  }
  return null;
};
