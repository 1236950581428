import machineLearning from "../../assets/Machine-learning.png";
import people from "../../assets/People.png";
import { Container, List, StyledImage, SubContainer } from "./style";

const Bulletin = () => {
  return (
    <Container>
      {data.map((item, index) => {
        return (
          <>
            <h2>{item.title}</h2>
            <SubContainer $reverse={index / 2 !== 0}>
              <List>
                {item.bullets.map((bullet) => {
                  return <li>{bullet}</li>;
                })}
              </List>
              <StyledImage src={item.image} />
            </SubContainer>
          </>
        );
      })}
    </Container>
  );
};

export default Bulletin;

const data = [
  {
    title: "What you will learn in this free masterclass",
    bullets: [
      "What is machine learning?",
      "What is deep learning?",
      "How does Neural Networks(NN) work?",
      "How to start any ML Project?",
      "How to create and use NN in projects",
    ],
    image: machineLearning,
  },
  {
    title: "This Free Masterclass is For You If",
    bullets: [
      "You are a student",
      "You are a ML Enthusiast",
      "You are passionate about ML",
      "Want to make your career in ML?",
      "Want career transition into ML?",
    ],
    image: people,
  },
];
