import { Content } from "./Content";
import Copyright from "./Copyright";
import {
  BrandsLinkArea,
  CompanyLinkArea,
  ProgramsLinkArea,
  SpecializationsLinkArea,
} from "./LinkArea";

import { Container } from "./style";

const Footer = () => {
  return (
    <>
      <Container>
        <Content />
        <CompanyLinkArea />
        <ProgramsLinkArea />
        <SpecializationsLinkArea />
        <BrandsLinkArea />
      </Container>
      <Copyright />
    </>
  );
};

export default Footer;
