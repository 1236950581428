import React, { useMemo } from "react";
import {
  CompanyLinksContainer,
  ProgramsLinksContainer,
  BrandLinksContainer,
  SpecializationsLinksContainer,
} from "./style";

const LinkArea = ({ title, links }) => {
  const linksList = useMemo(() => {
    return links.map(({ path, title }) => {
      return (
        <a href={path} target="_blank">
          {title}
        </a>
      );
    });
  }, [links]);
  return (
    <>
      <span className="title">{title}</span>
      <div className="link_area">{linksList}</div>
    </>
  );
};

export const CompanyLinkArea = () => {
  const links = [
    { path: "https://educations.alienbrains.in/about", title: "About" },
    { path: "https://medium.com/alienbrains", title: "Blogs" },
    {
      path: "https://cutshort.io/company/alien-brains-niSIjIpo",
      title: "Careers",
    },
    {
      path: "https://cutshort.io/company/alien-brains-niSIjIpo",
      title: "Internships",
    },
    {
      path: "https://educations.alienbrains.in/channelPartners",
      title: "Channel Partners",
    },
    {
      path: "https://educations.alienbrains.in/privacy_policy",
      title: "Privacy Policy",
    },
    { path: "https://educations.alienbrains.in/contact", title: "Contact Us" },
  ];
  return (
    <CompanyLinksContainer>
      <LinkArea title="Company" links={links} />
    </CompanyLinksContainer>
  );
};

export const ProgramsLinkArea = () => {
  const links = [
    {
      path: "https://educations.alienbrains.in/300",
      title: "300+ Hours Job Program",
    },
    { path: "/", title: "Career-Development Program" },
    { path: "/", title: "Certificate Program" },
    { path: "https://educations.alienbrains.in/workshop", title: "Workshops" },
  ];
  return (
    <ProgramsLinksContainer>
      <LinkArea title="Our Programs" links={links} />
    </ProgramsLinksContainer>
  );
};

export const SpecializationsLinkArea = () => {
  const links = [
    {
      path: "https://educations.alienbrains.in/live_classes/ML",
      title: "Machine Learning",
    },
    {
      path: "https://educations.alienbrains.in/live_classes/CS",
      title: "Cyber Security",
    },
    {
      path: "https://educations.alienbrains.in/live_classes/FSWD",
      title: "Full-Stack WebDev",
    },
    {
      path: "/",
      title: "Full-Stack AppDev",
    },
    {
      path: "https://educations.alienbrains.in/live_classes/PY",
      title: "Automate with Python",
    },
  ];
  return (
    <SpecializationsLinksContainer>
      <LinkArea title="Specialization" links={links} />
    </SpecializationsLinksContainer>
  );
};

export const BrandsLinkArea = () => {
  const links = [
    {
      path: "https://educations.alienbrains.in/",
      title: "Alien Brains Education",
    },
    { path: "https://isb.alienbrains.in/2021", title: `India's Super Brain` },
    { path: "https://sociana.com/", title: `Sociana` },
  ];
  return (
    <BrandLinksContainer>
      <LinkArea title="Brands" links={links} />
    </BrandLinksContainer>
  );
};
