import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerContainer = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 3px black solid;
  border-right-color: transparent !important;
  animation: ${rotate360} 0.5s linear infinite;
`;
