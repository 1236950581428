import logo from "../../assets/Logo.png";
import { Logo, NavbarContainer, StyledButton } from "./style";

const Navbar = () => {
  return (
    <NavbarContainer>
      <a href="https://educations.alienbrains.in/" target="_blank">
        <Logo src={logo} />
      </a>
      <a href="#registration">
        <StyledButton $primary>Register</StyledButton>
      </a>
    </NavbarContainer>
  );
};

export default Navbar;
