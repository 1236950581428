import quotes from "../../assets/icons/quotes.svg";
import Dasgupta from "../../assets/Devdeep Dasgupta.png";
import Ahmed from "../../assets/Salman Asif Ahmed.png";
import Pal from "../../assets/Somodyuti Pal.png";

import {
  Card,
  Container,
  ProfileContainer,
  QuotesImage,
  SubContainer,
} from "./style";

const Testimonials = () => {
  return (
    <Container>
      <h2>
        This Free Masterclass has already helped 100+ students get started
      </h2>
      <SubContainer>
        {data.map((person) => {
          return (
            <Card>
              <div>
                <p>{person.testimony}</p>
              </div>
              <ProfileContainer>
                <div>
                  <img src={person.image} />
                </div>
                <div>
                  <h4>{person.name}</h4>
                  <p>{person.batchName}</p>
                </div>
              </ProfileContainer>
              <QuotesImage src={quotes} />
            </Card>
          );
        })}
      </SubContainer>
    </Container>
  );
};

export default Testimonials;

const data = [
  {
    name: "Somodyuti Pal",
    image: Pal,
    batchName: "Machine Learning Batch",
    testimony:
      "Enrolled in the machine learning program and I must say, the commitment shown towards the all-round development of a student is unmatched. They give vast exposure to the market and remain very dynamic with the help and guidance they provide",
  },
  {
    name: "Salman Asif Ahmed",
    image: Ahmed,
    batchName: "Machine Learning Batch",
    testimony:
      "I completed the 150+ hours of Machine Learning course at Alien Brains. It was a great learning experience with extremely supportive mentors who helped me complete many projects within a short span of time. Highly recommended.",
  },
  {
    name: "Devdeep Dasgupta",
    image: Dasgupta,
    batchName: "Machine Learning Batch",
    testimony:
      "I had enrolled myself for a workshop on Automation with Python, conducted by Alien Brains. All the sessions were very interactive and I got to learn a lot from 10 live projects that were covered in the duration of the workshop. Looking forward to more future opportunities",
  },
];
