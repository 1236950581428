import styled from "styled-components";
import blobMobile from "../../assets/Hero-blob-mobile.svg";
import blobWeb from "../../assets/Hero-blob-web.svg";

export const Container = styled.div`
  width: 100%;
  padding: 50px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & a {
    text-decoration: none;
  }

  @media (min-width: 768px) {
    padding: 60px 80px 40px;
  }
`;

export const InfoCard = styled.div`
  position: relative;
  width: 100%;
  color: #ffffff;
  padding: 50px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #000;
  border-radius: 10px;
  overflow: hidden;
  z-index: 10;

  & .heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }

  & .image {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media (min-width: 768px) {
    padding: 30px;
    flex-direction: row;
    align-items: stretch;
    height: 400px;
    gap: 0px;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
    & .heading {
      margin-top: 20px;
      text-align: left;
      justify-content: space-evenly;
    }
    & .image {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    & .info {
      flex: 0 1 300px;
      align-items: flex-start;
    }
  }

  @media (min-width: 1024px) {
    padding: 30px 30px 30px 70px;
    height: 300px;
    display: flex;
    gap: 0px;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
    & .heading {
      margin-top: 0px;
    }
    & .image {
      flex: 0 0 290px;
      display: flex;
      height: 266px;
      justify-content: flex-end;
      align-items: flex-end;
    }
    & .info {
      flex: 0 0 235px;
      align-items: center;
    }
  }
`;

export const Blob = styled.div`
  position: absolute;
  bottom: -50px;
  right: 0;
  width: 100%;
  height: 500px;
  border-radius: 10px;
  z-index: -1;
  background: url(${blobMobile}) no-repeat right top;
  background-size: cover;

  @media (min-width: 768px) {
    height: 410px;
    width: max(58%, 650px);
    bottom: -2px;
    right: 0;
    background: url(${blobWeb}) no-repeat left bottom;
    background-size: cover;
  }

  @media (min-width: 1024px) {
    width: max(68%, 750px);
  }
`;

export const LightBlob = styled(Blob)`
  opacity: 0.3;
  bottom: -35px;

  @media (min-width: 768px) {
    display: none !important;
    right: -45px;
    bottom: -4px;
    transform: rotate(2deg);
  }
`;

export const StyledTag = styled.div`
  background: linear-gradient(223.29deg, #fe2f9f 16.31%, #f87700 93.06%);
  height: 40px;
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: auto;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-left: auto;

  @media (min-width: 768px) {
    margin-left: 0;
  }
`;

export const Logo = styled.img`
  height: 45px;
  width: auto;
  object-fit: contain;
`;

export const Heading = styled.h3`
  font-weight: 500;
  margin: 10px 0 30px;

  & div {
    color: #fece2f;
    font-size: 1.7rem;
  }

  @media (min-width: 768px) {
    margin: 0;
  }
`;

export const Image = styled.img`
  width: min(100%, 300px);
  margin-bottom: -4px;
  width: 250px;

  @media (min-width: 768px) {
    width: 150px;
  }

  @media (min-width: 1024px) {
    width: 250px;
  }
`;

export const StyledArrow = styled.img`
  display: none;

  @media (min-width: 768px) {
    display: inherit;
    margin: 0 0 10px -40px;
    width: 60px;
  }

  @media (min-width: 1024px) {
    width: auto;
    margin: 0 0 30px -55px;
  }
`;

export const Card = styled.div`
  width: 235px;
  color: #ffffff;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 400;
  background: #000;
  border-radius: 10px;
  margin-bottom: 10px;

  & div {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  & p {
    font-size: 14px;
  }

  & .highlight {
    color: #fece2f;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
    padding: 15px 20px;
    flex-wrap: wrap;
    width: 145px;
  }

  @media (min-width: 1024px) {
    width: 235px;
  }
`;

export const StyledButton = styled.button`
  font-weight: 500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 10px;
  background: #ffdd00;
  font-size: 1.4rem;
  padding: 15px 20px;
  font-weight: 700;
  transition: transform linear 0.2s;
  &:hover {
    box-shadow: 0 15px 23px 0 #fff6dc;
  }
  &:active {
    transform: scale(0.9);
  }
`;

export const DescriptionCard = styled.div`
  width: 100%;
  background: #fff6dc;
  border: 3px solid #fece2f;
  border-radius: 10px;
  margin: 40px 0;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px 20px;

  & h3 {
    margin-bottom: 15px;
  }

  @media (min-width: 768px) {
    padding: 15px 60px;
  }
`;

export const Content = styled.div`
  color: #5c5c5c;
  margin: 20px 0;
  text-align: center;
`;

export const TagContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const Tag = styled.div`
  background: #ffffff;
  border: 1.5px solid #fece2f;
  border-radius: 6px;
  padding: 5px 20px;
  font-size: 0.9rem;
  font-weight: 650;
`;

export const Details = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & img {
    width: 20px;
  }
`;
