import { SpinnerContainer } from "./style";

const ActivityIndicator = ({ color = "" }) => {
  return (
    <SpinnerContainer
      style={{
        borderColor: color,
      }}
    />
  );
};

export default ActivityIndicator;
