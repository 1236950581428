import logo from "../../assets/Logo.png";
import facebook from "../../assets/icons/facebook.svg";
import twitter from "../../assets/icons/twitter.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";

import {
  ContentContainer,
  ContentLogo,
  SocialIconsContainer,
  SocialIconBox,
} from "./style";

const SocialIcon = ({ href, image, ...rest }) => {
  return (
    <SocialIconBox href={href} target="_blank" className="cc">
      <img src={image} />
    </SocialIconBox>
  );
};

export const Content = () => {
  return (
    <ContentContainer>
      <ContentLogo>
        <img src={logo} alt="company logo" />
      </ContentLogo>
      <span className="desc">
        Mentoring you the way we wanted someone to mentor us. We organize
        workshops, seminars and mentorship programs for career enthusiasts.
      </span>
      <SocialIconsContainer>
        <SocialIcon
          href="https://www.facebook.com/educations.alienbrains.in"
          image={facebook}
        />
        <SocialIcon href="https://twitter.com/alienbrains_" image={twitter} />
        <SocialIcon
          href="https://www.instagram.com/alienbrains_educations/"
          image={instagram}
        />
        <SocialIcon
          href="https://www.linkedin.com/company/alien-brains/mycompany/verification/"
          image={linkedin}
        />
      </SocialIconsContainer>
    </ContentContainer>
  );
};
