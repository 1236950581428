import mentorImg from "../../assets/Mentor.png";
import calendar from "../../assets/icons/calendar.svg";
import clock from "../../assets/icons/clock.svg";
import calendarRed from "../../assets/icons/calendar-red.svg";
import clockRed from "../../assets/icons/clock-red.svg";
import mic from "../../assets/icons/mic.svg";
import arrow from "../../assets/Arrow.svg";
import {
  Blob,
  Card,
  Container,
  Content,
  DescriptionCard,
  Details,
  Heading,
  Image,
  InfoCard,
  LightBlob,
  StyledArrow,
  StyledButton,
  StyledTag,
  Tag,
  TagContainer,
} from "./style";
import { LoopText } from "../../components/LoopText";
import { Ribbon } from "../../components/Ribbon";

const dataText = [
  "How to get started in machine learning.",
  "How to start your career in machine learning.",
  "How to start your journey for the dream job.",
];

const Hero = () => {
  return (
    <Container>
      <InfoCard>
        <Ribbon bgColor={"#fece2f"}>Free Masterclass</Ribbon>
        <div className="heading">
          <Heading>
            Save your time spent in research and decision making. Join our Free
            Masterclass on
            <div>
              <LoopText textData={dataText} />
            </div>
          </Heading>
        </div>
        <div className="image">
          <Image src={mentorImg} />
          <StyledArrow src={arrow} />
        </div>
        <div className="info">
          <Card>
            <div>
              <img src={mic} />
              <h5>Speaker</h5>
            </div>
            <div>
              <h4>Sweta Shaw</h4>
            </div>
            <p>Lead Machine Learning Mentor</p>
            <p className="highlight">Alien Brains Education</p>
          </Card>
          <Card>
            <div>
              <img src={calendar} />
              <h5>9th July, 2022</h5>
            </div>
            <div>
              <img src={clock} />
              <h5>10:30 A.M. - 6:30 P.M.</h5>
            </div>
          </Card>
        </div>
        <Blob />
        <LightBlob />
      </InfoCard>
      <a href="#registration">
        <StyledButton>Register for the Free Masterclass</StyledButton>
      </a>
      <h4>*Limited Seats</h4>
      <DescriptionCard>
        <h2>Machine Learning Masterclass</h2>
        <Content>
          In this Masterclass we will dive in the details of Neural Networks and
          understand how things work in Deep Learning. We will get our hands
          dirty with some codes to work on a Project using Neural Network.
        </Content>
        <h3>Highlights:</h3>
        <TagContainer>
          <Tag>Neural Networks</Tag>
          <Tag>Deep Learning</Tag>
          <Tag>Machine Learning Project</Tag>
          <Tag>Long Hours Session</Tag>
          <Tag>Free Masterclass</Tag>
        </TagContainer>
        <TagContainer>
          <Details>
            <img src={calendarRed} />
            <h4>9th July, 2022</h4>
          </Details>
          <Details>
            <img src={clockRed} />
            <h4>10:30 A.M. - 6:30 P.M.</h4>
          </Details>
        </TagContainer>
      </DescriptionCard>
    </Container>
  );
};

export default Hero;
