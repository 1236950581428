import { Container, Image, StyledButton } from "./style";

const Ebook = () => {
  return (
    <Container>
      <div>
        <h2>What will You Get?</h2>
        <h1>E-Book worth ₹2000 for FREE</h1>
        <a href="#registration">
          <StyledButton>Register to unlock</StyledButton>
        </a>
        <p>*Registered attendees will receive the E-book on their email.</p>
      </div>
      <div>
        <Image />
      </div>
    </Container>
  );
};

export default Ebook;
