import styled from "styled-components";

export const Cursor = styled.span`
  border-left: 2px solid white;
  height: 20px;
  animation: blink 0.9s steps(1) infinite;
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
`;
