import { useState } from "react";
import tick from "../../assets/icons/tick.svg";
import ticket from "../../assets/icons/ticket.svg";
import error from "../../assets/icons/error.svg";
import success from "../../assets/icons/success.svg";

import {
  Container,
  CustomCheckbox,
  InputRow,
  RowContainer,
  StyledButton,
  StyledError,
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledSuccess,
} from "./style";
import { useCreateRegistration } from "./useCreateRegistration";
import { useFetchOtp } from "./useFetchOtp";
import Alert from "../../components/Alert";
import ActivityIndicator from "../../components/ActivityIndicator";
import { createErrorMessage } from "./utils";
import StartTimer from "./timer";

const Registration = () => {
  const [details, setDetails] = useState({
    name: "",
    phone: "",
    email: "",
    otp: "",
    tnc: false,
  });

  const [otpStatus, setOtpStatus] = useState({ success: false, message: "" });

  const [registrationStatus, setRegistrationStatus] = useState({
    success: null,
    message: "",
  });

  const [timerActive, setTimerActive] = useState(false);

  const { isLoading: isOtpGenerating, fetchOtp } = useFetchOtp();
  const { isLoading: isRegistering, createRegistration } =
    useCreateRegistration();

  const handleChange = (e) => {
    setDetails((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleCheckbox = () => {
    setDetails((prev) => ({ ...prev, tnc: !prev.tnc }));
  };

  const handleOtpApiResponse = (response) => {
    if (!!response?.errors) {
      setOtpStatus({
        success: false,
        message:
          createErrorMessage(response?.errors) || response?.meta?.message,
      });
    } else {
      setOtpStatus({ success: true, message: "" });
    }
  };

  const handleRegistrationApiResponse = (response) => {
    if (!!response?.errors) {
      setRegistrationStatus({
        success: false,
        message:
          createErrorMessage(response?.errors) || response?.meta?.message,
      });
    } else {
      setRegistrationStatus({ success: true, message: "" });
    }
  };

  const onAlertClose = () => {
    if (registrationStatus.success) {
      setOtpStatus({ success: false, message: "" });
      setRegistrationStatus({
        success: null,
        message: "",
      });
      setDetails({
        name: "",
        phone: "",
        email: "",
        otp: "",
        tnc: false,
      });
    } else {
      setRegistrationStatus({
        success: null,
        message: "",
      });
    }
  };

  const getOtp = async (e) => {
    e.preventDefault();
    setOtpStatus({ success: false, message: "" });
    const response = await fetchOtp(details.phone, details.email);
    handleOtpApiResponse(response);
  };

  const onRegister = async (e) => {
    e.preventDefault();
    const response = await createRegistration(
      details.name,
      details.phone,
      details.email,
      details.otp,
    );
    handleRegistrationApiResponse(response);
  };

  return (
    <Container id="registration">
      <h2>Registration Form</h2>
      <StyledForm>
        <StyledLabel for="name">Full Name*</StyledLabel>
        <StyledInput
          id="name"
          placeholder="Enter your full name"
          value={details.name}
          onChange={handleChange}
          required
        />
        <InputRow>
          <div>
            <StyledLabel for="phone">Phone Number*</StyledLabel>
            <StyledInput
              id="phone"
              placeholder="Enter your phone number"
              inputMode="tel"
              value={details.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <StyledLabel for="email">Email Address*</StyledLabel>
            <StyledInput
              id="email"
              placeholder="Enter your email address"
              inputMode="email"
              value={details.email}
              onChange={handleChange}
              required
            />
          </div>
        </InputRow>
        <StyledButton
          onClick={getOtp}
          disabled={
            !details.name || !details.phone || !details.email || timerActive
          }
        >
          {isOtpGenerating ? (
            <ActivityIndicator color="#FFDD00" />
          ) : otpStatus.success ? (
            "Resend OTP"
          ) : (
            "Get OTP"
          )}
        </StyledButton>
        {!otpStatus.success && otpStatus.message && (
          <StyledError>
            <img src={error} />
            {otpStatus.message}
          </StyledError>
        )}
        {otpStatus.success && (
          <StyledSuccess>
            {timerActive && (
              <>
                <img src={success} /> Your OTP has been generated. You can
                re-generate OTP in{" "}
              </>
            )}
            <StartTimer
              targetDate={new Date().getTime() + 30000}
              setTimerActive={setTimerActive}
            />
          </StyledSuccess>
        )}
        <StyledLabel for="otp">Enter OTP</StyledLabel>
        <StyledInput
          id="otp"
          placeholder="Enter your OTP here"
          inputMode="numeric"
          value={details.otp}
          onChange={handleChange}
          disabled={!otpStatus.success}
          required
        />
        <RowContainer style={{ justifyContent: "flex-start" }}>
          <CustomCheckbox onClick={handleCheckbox} $active={details.tnc}>
            <img src={tick} />
          </CustomCheckbox>
          <StyledLabel>
            Information you provide while registering will be shared with the
            account owner, and the host can use them as per their{" "}
            <a href="https://educations.alienbrains.in/privacy_policy">
              Terms and Privacy Policy
            </a>
            .
          </StyledLabel>
        </RowContainer>
        <StyledButton
          $primary
          style={{ margin: "20px 0" }}
          disabled={
            !details.name ||
            !details.phone ||
            !details.email ||
            !details.otp ||
            !details.tnc
          }
          onClick={onRegister}
        >
          {isRegistering ? (
            <ActivityIndicator color="#050505" />
          ) : (
            "Register for the Masterclass"
          )}
        </StyledButton>
        <RowContainer>
          <img src={ticket} />
          Limited Seats
        </RowContainer>
      </StyledForm>
      {registrationStatus.success !== null && (
        <Alert
          type={!!registrationStatus.success && "success"}
          message={registrationStatus.message}
          onClose={onAlertClose}
        />
      )}
    </Container>
  );
};

export default Registration;
