import styled from "styled-components";
import bullet from "../../assets/icons/bullet.svg";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 0 80px;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  gap: 80px;
  width: 100%;
  padding: 50px 0 80px;
  flex-wrap: wrap;
  ${(props) => props.$reverse && "flex-direction: row-reverse"}
`;

export const List = styled.ul`
  list-style-image: url(${bullet});
  font-size: 1.1rem;
  font-weight: 700;
  margin-left: 20px;

  & li:not(:last-child) {
    height: 60px;
  }
`;

export const StyledImage = styled.img`
  width: min(100%, 500px);
  max-height: 320px;
  object-fit: contain;
`;
