import { useState } from "react";
import { convertObjectToFormData } from "./utils";

export const useCreateRegistration = () => {
  const [isLoading, setLoading] = useState(false);

  const createRegistration = async (name, phone, email, otp) => {
    const data = {
      webinar_registration_modelable: {
        name: name,
        email: email,
        phone: phone,
      },
      webinar_registration: {
        modelable_type: "ExternalStudent",
      },
    };

    const formData = new FormData();
    convertObjectToFormData(data, formData, "");

    setLoading(true);

    const response = await fetch(
      `https://alientraining.herokuapp.com/api/v3/leads/webinars/3/webinar_registrations?code=${otp}`,
      {
        method: "POST",
        body: formData,
      },
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return { error };
      });

    setLoading(false);
    return response;
  };

  return {
    isLoading,
    createRegistration,
  };
};
