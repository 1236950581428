import React from "react";
import { RibbonContainer, RibbonChild } from "./style";

export const Ribbon = ({ children, bgColor }) => {
  return (
    <RibbonContainer>
      <RibbonChild $color={bgColor}>{children}</RibbonChild>
    </RibbonContainer>
  );
};
