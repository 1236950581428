import speaker from "../../assets/Speaker.png";
import { Card, Container } from "./style";

const Profile = () => {
  return (
    <Container>
      <h2>Speaker</h2>
      <Card>
        <img src={speaker} />
        <div>
          <h3>Sweta Shaw</h3>
          <p>Lead Machine Learning Mentor</p>
          <p>Alien Brains</p>
        </div>
      </Card>
    </Container>
  );
};

export default Profile;
