import bgWeb from "../../assets/Ebook-bg-web.png";
import bgMobile from "../../assets/Registration-bg-mobile.png";
import ebookWeb from "../../assets/Ebook-web.png";
import ebookMobile from "../../assets/Ebook-mobile.png";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff6dc url(${bgMobile}) no-repeat right bottom;
  padding: 50px 20px 0;
  margin-bottom: 80px;
  gap: 30px;
  text-align: center;

  & h2 {
    margin-bottom: 20px;
  }

  & a {
    text-decoration: none;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    text-align: left;
    padding: 20px 0px 0px 50px;
    background: #fff6dc url(${bgWeb}) no-repeat right top;
  }

  @media (min-width: 1024px) {
    padding: 20px 50px 0px 80px;
  }
`;

export const Image = styled.div`
  height: 370px;
  width: 100vw;
  max-width: 370px;
  background: url(${ebookMobile}) no-repeat right top;
  background-size: contain;

  @media (min-width: 768px) {
    height: 370px;
    width: 430px;
    max-width: 430px;
    background: url(${ebookWeb}) no-repeat right top;
    background-size: contain;
  }

  @media (min-width: 1024px) {
    height: 400px;
    width: 600px;
    max-width: 600px;
    background: url(${ebookWeb}) no-repeat right top;
    background-size: contain;
  }
`;

export const StyledButton = styled.button`
  height: 46px;
  background: #ffdd00;
  color: #000;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: 30px auto 20px auto;

  &:hover {
    box-shadow: 0 15px 23px 0 #ffdd0030;
  }
  &:active {
    ${(props) => !props.disabled && "transform: scale(0.9);"}
  }

  @media (min-width: 768px) {
    margin: 30px 0 20px 0;
  }
`;
