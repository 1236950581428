import Error from "./Error";
import Success from "./Success";
import { Container } from "./style";

const Alert = ({ type, message, onClose }) => {
  return (
    <Container>
      {type == "success" ? (
        <Success onClose={onClose} />
      ) : (
        <Error message={message} onClose={onClose} />
      )}
    </Container>
  );
};

export default Alert;
