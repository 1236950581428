import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #000000c3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  position: relative;
  width: 430px;
  background: #fff;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
  border-radius: 10px;
  margin: 0 20px 50px;
  box-shadow: 0px 20px 50px 0px #00000026;
  overflow: hidden;

  & .icon {
    width: 70px;
  }
`;

export const StyledButton = styled.button`
  width: 100%;
  border-radius: 6px;
  padding: 8px 10px;
  color: #fff;
  background: ${(props) => (props.$success ? "#0DA018" : "#F93E3E")};
  border: none;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 6px 13px 0px
      ${(props) => (props.$success ? "#0DA0184A" : "#F93E3E4A")};
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const BgImage = styled.img`
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
`;
