import styled from "styled-components";

export const NavbarContainer = styled.div`
  width: 100%;
  height: 80px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 6px 20px 0px #0000000f;

  & a {
    text-decoration: none;
  }

  @media (min-width: 768px) {
    padding: 0 80px;
  }
`;

export const Logo = styled.img`
  height: 50px;
  width: auto;
`;

export const StyledButton = styled.button`
  height: 46px;
  background: ${(props) => (props.$primary ? "#FFDD00" : "#000000")};
  color: ${(props) => (props.$primary ? "#000000" : "#FFDD00")};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    box-shadow: 0 15px 23px 0
      ${(props) => (props.$primary ? "#fff6dc" : "#00000030")};
  }
  &:active {
    ${(props) => !props.disabled && "transform: scale(0.9);"}
  }
`;
