import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 0 40px;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  gap: 40px;
  width: 100%;
  padding: 50px 0 80px;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  position: relative;
  width: 100%;
  max-width: 360px;
  height: 360px;
  background: #fff6dc;
  border-radius: 10px;
  padding: 75px 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & p {
    font-size: 0.95rem;
    color: #5c5c5c;
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    height: 300px;
  }
`;

export const QuotesImage = styled.img`
  position: absolute;
  top: 20px;
  left: 25px;
  height: 50px;
  z-index: 1000;
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;

  & img {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }
`;
