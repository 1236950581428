import { useEffect, useRef, useState } from "react";

const StartTimer = ({ targetDate, setTimerActive }) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime(),
  );

  let intervalRef = useRef();

  useEffect(() => {
    if (!intervalRef.current) {
      setTimerActive(true);
      intervalRef.current = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime());
      }, 1000);
    }
    if (countDown < 1000) {
      clearInterval(intervalRef.current);
      setTimerActive(false);
    }
  }, [countDown]);

  return getRemainingTime(countDown);
};

const getRemainingTime = (countDown) => {
  let remainingTime = "";

  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  if (days) {
    remainingTime = remainingTime + `${days} days`;
  }

  if (hours) {
    remainingTime = remainingTime + `${days} hrs`;
  }

  if (minutes) {
    remainingTime = remainingTime + `${minutes} mins`;
  }

  if (seconds) {
    remainingTime = remainingTime + `${seconds} secs`;
  }

  return remainingTime;
};

export default StartTimer;
