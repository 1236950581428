import error from "../../assets/icons/error.svg";
import { Card, StyledButton } from "./style";

const Error = ({ message, onClose }) => {
  return (
    <Card>
      <img src={error} className="icon" />
      <h2>Oops!</h2>
      <p>{message || "Something went wrong."}</p>
      <StyledButton onClick={onClose}>Try Again</StyledButton>
    </Card>
  );
};

export default Error;
