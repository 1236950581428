import success from "../../assets/icons/success.svg";
import confetti from "../../assets/Confetti.png";
import { BgImage, Card, StyledButton } from "./style";

const Success = ({ onClose }) => {
  return (
    <Card>
      <img src={success} className="icon" />
      <h2>Success!</h2>
      <p>
        Your registration has been successful. Check your email for details.
      </p>
      <StyledButton onClick={onClose} $success>
        Okay
      </StyledButton>
      <BgImage src={confetti} />
    </Card>
  );
};

export default Success;
